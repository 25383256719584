span {
  font-weight: bold;
  color: red;
  float: right;
}

.uploadForm {
  padding: 15px;
  margin-top: 30px;
  border-style: 1px;
  border-color: grey;
  border-radius: 15px;
  z-index: -1;
  box-shadow: 10px 10px 10px 10px grey;
  align-content: flex;
  justify-content: flex-start;
}

form {
  z-index: 0;
}

.uploadForm label {
  font-weight: bold;
}



input[type='file'] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

label[for=chooseFile] {
  font-size: 20px;
  color: white;
  cursor: pointer;
  padding: 5px;
  border-radius: 10px;
  background-color: grey;
}

label[for=chooseFile]:hover {
  background-color: burlywood;
}